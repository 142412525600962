<template>
  <!-- loading START -->
  <transition name="dialog-fade" mode="out-in">
    <div
      class="dialog-numpad dialog-numpad-default"
      v-if="dialogNumpadVisible"
    >
      <div class="dialog-numpad-default-container" v-if="!inactiveButton">

        <!-- dialog:numpad:display START -->
        <el-input
          class="quantity"
          type="number"
          inputmode="numeric"
          v-model="productEan"
        ></el-input>
        <!-- dialog:numpad:display END -->

        <!-- dialog:numpad:keys START -->
        <div class="numpad-holder-dialog">
          <div class="numpad-dialog">
            <div
              class="button"
              v-on:click="append('1')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >1</div>
            <div
              class="button"
              v-on:click="append('2')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >2</div>
            <div
              class="button"
              v-on:click="append('3')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >3</div>
            <div
              class="button"
              v-on:click="append('4')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >4</div>
            <div
              class="button"
              v-on:click="append('5')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >5</div>
            <div
              class="button"
              v-on:click="append('6')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >6</div>
            <div
              class="button"
              v-on:click="append('7')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >7</div>
            <div
              class="button"
              v-on:click="append('8')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >8</div>
            <div
              class="button"
              v-on:click="append('9')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >9</div>
            <div
              class="button remove"
              v-on:click="clear()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >DEL</div>
            <div
              class="button"
              v-on:click="append('0')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >0</div>
            <div
              class="button o-k"
              :class="[
                { 'nofity' : productEan !== '' }
              ]"
              v-on:click="add()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            >OK</div>
          </div>
        </div>
        <!-- dialog:numpad:keys END -->

      </div>
    </div>
  </transition>
  <!-- loading END -->
</template>

<script>
export default {
  name: 'dialog-numpad',
  props: {
    dialogNumpadVisible: {
      type: Boolean,
      default: false,
    },
    closeNumpad: {
      type: Function,
    },
  },
  data() {
    return {
      productEan: '',
      inactiveButton: false,
    };
  },
  methods: {
    append(q) {
      this.productEan += q.toString();
    },

    clear() {
      this.productEan = this.productEan
        .substr(0, this.productEan.length - 1);
    },

    add() {
      this.inactiveButton = true;
      setTimeout(() => {
        this.closeNumpad(this.productEan);
        setTimeout(() => {
          this.productEan = '';
          this.inactiveButton = false;
        }, 150);
      }, 150);

      // this.closeNumpad(this.productEan);
      // setTimeout(() => {
      //   this.productEan = '';
      // }, 150);
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
};
</script>
